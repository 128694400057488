export let languageList = [
  {
    languageName: "简体中文",
    languageCode: "zh_CN",
  },
  // {
  //   languageName: "繁体中文",
  //   languageCode: "zh_TW",
  // },
  {
    languageName: "英语",
    languageCode: "en",
  },
  {
    languageName: "阿拉伯语",
    languageCode: "ar",
  },
  // {
  //   languageName: "孟加拉语",
  //   languageCode: "bn",
  // },
  {
    languageName: "德语",
    languageCode: "de",
  },
  // {
  //   languageName: "西班牙语",
  //   languageCode: "es",
  // },
  // {
  //   languageName: "法语",
  //   languageCode: "fr",
  // },
  // {
  //   languageName: "印地语",
  //   languageCode: "hi",
  // },
  // {
  //   languageName: "印度尼西亚语",
  //   languageCode: "id",
  // },
  // {
  //   languageName: "意大利语",
  //   languageCode: "it",
  // },
  {
    languageName: "日语",
    languageCode: "ja",
  },
  // {
  //   languageName: "韩语",
  //   languageCode: "ko",
  // },
  // {
  //   languageName: "荷兰语",
  //   languageCode: "nl",
  // },
  {
    languageName: "葡萄牙语",
    languageCode: "pt",
  },
  {
    languageName: "俄语",
    languageCode: "ru",
  },
  {
    languageName: "泰语",
    languageCode: "th",
  },
  {
    languageName: "土耳其语",
    languageCode: "tr",
  },
  {
    languageName: "越南语",
    languageCode: "vi",
  },
  {
    languageName: "波斯语",
    languageCode: "fa",
  },
];
