<template>
  <div
    class="floating-ad"
    :class="{
      expanded: isExpanded,
      dragging: isDragging,
      minimized: isMinimized,
    }"
    @mousedown="startDrag"
    @touchstart="startDrag"
    ref="ad"
  >
    <!-- 只有在未缩小时才显示内容 -->
    <div class="drag-area" v-if="!isMinimized" @click.stop="handleClick">
      <div class="floating-ad-content" v-if="!isExpanded">
        <!-- <p>广告内容</p> -->
        <!-- 左侧/右侧箭头 -->
        <div
          v-if="!isExpanded && position === 'left'"
          class="arrow arrow-top-left"
          @click.stop="handleClick"
        >
          <el-icon>
            <ZoomIn />
          </el-icon>
        </div>
        <div
          v-if="!isExpanded && position === 'right'"
          class="arrow arrow-top-right"
          @click.stop="handleClick"
        >
          <el-icon>
            <ZoomIn />
          </el-icon>
        </div>
        <div
          v-if="!isExpanded && position === 'left'"
          class="arrow arrow-left"
          @click.stop="minimize('left')"
        >
          <el-icon><ArrowLeftBold /></el-icon>
        </div>
        <div
          v-if="!isExpanded && position === 'right'"
          class="arrow arrow-right"
          @click.stop="minimize('right')"
        >
          <el-icon><ArrowRightBold /></el-icon>
        </div>
      </div>
      <div class="expanded-content">
        <!-- <iframe class="m-auto" src="https://www.lm.ink/V8pxQwpv14JR8"></iframe> -->
      </div>
    </div>

    <!-- 收缩状态下点击恢复 -->
    <div
      v-if="isMinimized"
      class="minimized-bar"
      @click.stop="toggleExpand"
    ></div>

    <!-- 收缩按钮 -->
    <button
      class="collapse-btn"
      :class="{ hidden: !isExpanded }"
      @click.stop="toggleExpand"
    >
      &#x2715;
    </button>
  </div>
</template>

<script>
export default {
  name: "Demo7-vue",
  data() {
    return {
      isExpanded: false,
      isDragging: false,
      isMinimized: false, // 是否处于条状栏状态
      startX: 0,
      startY: 0,
      initialLeft: 0,
      initialTop: 0,
      isClicked: false, // 区分点击与拖动
      position: "right", // 初始位置
    };
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      this.isMinimized = false; // 恢复正常状态
      this.$nextTick(() => {
        this.adjustPosition();
      });
    },
    handleClick() {
      if (!this.isDragging && !this.isExpanded && !this.isMinimized) {
        this.toggleExpand();
      }
    },
    startDrag(event) {
      this.isDragging = true;
      // this.isClicked = true;
      // if (this.isClicked) {
      //   this.isDragging = true;
      //   this.isClicked = false;
      // }

      const adElement = this.$refs.ad;
      this.startX = event.clientX || event.touches[0].clientX;
      this.startY = event.clientY || event.touches[0].clientY;
      this.initialLeft = adElement.getBoundingClientRect().left;
      this.initialTop = adElement.getBoundingClientRect().top;

      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.stopDrag);
      document.addEventListener("touchmove", this.onDrag);
      document.addEventListener("touchend", this.stopDrag);
    },
    onDrag(event) {
      if (this.isDragging) {
        const clientX = event.clientX || event.touches[0].clientX;
        const clientY = event.clientY || event.touches[0].clientY;
        const adElement = this.$refs.ad;

        adElement.style.left = `${
          this.initialLeft + (clientX - this.startX)
        }px`;
        adElement.style.top = `${this.initialTop + (clientY - this.startY)}px`;
      }
    },
    stopDrag() {
      this.isDragging = false;
      // this.isClicked = false;
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.stopDrag);
      document.removeEventListener("touchmove", this.onDrag);
      document.removeEventListener("touchend", this.stopDrag);
      this.adjustPosition();
    },
    minimize(position) {
      this.isMinimized = true; // 进入条状栏状态
      this.isExpanded = false;
      if (position === "left") {
        this.position = "left";
      } else {
        this.position = "right";
      }
    },
    adjustPosition() {
      const adElement = this.$refs.ad;
      const rect = adElement.getBoundingClientRect();
      const screenWidth = window.innerWidth;

      if (this.isExpanded) {
        adElement.style.left = "50%";
        adElement.style.top = "50%";
        adElement.style.transform = "translate(-50%, -50%) scale(1.5)";
      } else if (!this.isMinimized) {
        if (rect.left + rect.width / 2 < screenWidth / 2) {
          this.position = "left";
          adElement.style.left = "5px";
          adElement.style.right = "auto";
        } else {
          this.position = "right";
          adElement.style.right = "5px";
          adElement.style.left = "auto";
        }
        //adElement.style.top = "auto";
        //adElement.style.bottom = "5px";
        adElement.style.transform = "none";
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.adjustPosition);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.adjustPosition);
  },
};
</script>

<style scoped>
.floating-ad {
  position: fixed;
  bottom: 5px;
  right: 5px;
  width: 200px;
  height: 100px;
  background-color: #007bff;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease,
    bottom 0.3s ease, right 0.3s ease, left 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 最小化状态下的样式 */
.floating-ad.minimized {
  width: 20px;
  height: 50px;
  background-color: #555;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.minimized-bar {
  width: 20px;
  height: 50px;
  background-color: #333;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
}

.drag-area {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  position: absolute;
  font-size: 20px;
  color: rgb(255, 255, 255);
}

.arrow-left {
  right: 2px;
}

.arrow-right {
  left: 2px;
}

.floating-ad.expanded {
  transform: scale(1.5);
  width: 300px;
  height: 300px;
  bottom: auto;
  right: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.5); /* Centering */
}

.floating-ad-content,
.expanded-content {
  text-align: center;
}

.collapse-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.arrow-top-right {
  right: 10px;
  top: 10px;
}
.arrow-top-left {
  left: 10px;
  top: 10px;
}
.collapse-btn.hidden {
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 768px) {
  .floating-ad {
    width: 100px;
    height: 50px;
  }

  .floating-ad.expanded {
    width: 225px;
    height: 225px;
  }

  .collapse-btn {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 480px) {
  .floating-ad {
    width: 160px;
    height: 80px;
  }

  .floating-ad.expanded {
    width: 180px;
    height: 180px;
  }

  .collapse-btn {
    width: 20px;
    height: 20px;
  }
}

.floating-ad.dragging {
  cursor: grabbing;
}
</style>
