export let languageList = [
  {
    languageName: "简体中文",
    languageCode: "zh_CN",
  },
  // {
  //   languageName: "繁体中文",
  //   languageCode: "zh_TW",
  // },
  {
    languageName: "English",
    languageCode: "en",
  },
  {
    languageName: "العربية",
    languageCode: "ar",
  },
  // {
  //   languageName: "বাংলা",
  //   languageCode: "bn",
  // },
  {
    languageName: "Deutsch",
    languageCode: "de",
  },
  // {
  //   languageName: "Español",
  //   languageCode: "es",
  // },
  // {
  //   languageName: "Français",
  //   languageCode: "fr",
  // },
  // {
  //   languageName: "हिंदी",
  //   languageCode: "hi",
  // },
  // {
  //   languageName: "Indonesia",
  //   languageCode: "id",
  // },
  // {
  //   languageName: "Italiano",
  //   languageCode: "it",
  // },
  {
    languageName: "日本語",
    languageCode: "ja",
  },
  // {
  //   languageName: "한국어",
  //   languageCode: "ko",
  // },
  // {
  //   languageName: "Nederlands",
  //   languageCode: "nl",
  // },
  {
    languageName: "Português",
    languageCode: "pt",
  },
  {
    languageName: "Русский язык",
    languageCode: "ru",
  },
  {
    languageName: "ภาษาไทย",
    languageCode: "th",
  },
  {
    languageName: "Türkçe",
    languageCode: "tr",
  },
  {
    languageName: "Việt Nam",
    languageCode: "vi",
  },
  {
    languageName: " فارسی-",
    languageCode: "fa",
  },
];
