<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { getIpInfo } from "@/http/modules/login";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
//import axios from "axios";
export default {
  name: "App",
  components: {},
  setup() {
    const store = useStore();
    const isInteracting = ref(false);
    const startTime = ref(null);
    const elapsedTime = ref(0);
    let interactionTimeout = null;
    let timer = null;

    const handleUserInteraction = () => {
      if (!isInteracting.value) {
        isInteracting.value = true;
        startTimer();
      }

      // 重置计时器
      clearTimeout(interactionTimeout);
      interactionTimeout = setTimeout(() => {
        isInteracting.value = false;
        stopTimer();
      }, 3000);
    };
    // const isLinkValid = async () => {
    //   try {
    //     const response = await axios.get(
    //       "https://pbs.twimg.com/profile_images/1824316060109250560/pYYMzlfo_normal.png",
    //       {
    //         // 注意：这里不能使用 `no-cors`，因为 axios 不支持这种模式。
    //         // 你可以通过代理或服务器端解决 CORS 问题。
    //         timeout: 8000, // 设置请求超时
    //       }
    //     );

    //     if (response.status === 200) {
    //       console.log("资源有效");
    //     } else {
    //       console.log("资源无效，状态码:", response.status);
    //     }
    //   } catch (error) {
    //     if (error.response) {
    //       // 请求已发出，但服务器响应了一个状态码，非 2xx 范围
    //       console.log("资源无效，状态码:", error.response.status);
    //     } else if (error.request) {
    //       // 请求已发出，但没有收到服务器的响应
    //       console.log("没有收到服务器响应");
    //     } else {
    //       // 其他错误
    //       console.log("请求失败:", error.message);
    //     }
    //   }
    //   // await fetch(
    //   //   "https://pbs.twimg.com/profile_images/1824316060109250560/pYYMzlfo_normal.png",
    //   // )
    //   //   .then((response) => {
    //   //     console.log(response);
    //   //     if (response.ok) {
    //   //       console.log("资源有效");
    //   //     } else {
    //   //       console.log("资源无效，状态码:", response.status);
    //   //     }
    //   //   })
    //   //   .catch(() => {
    //   //     console.log("Link is not valid");
    //   //     return false;
    //   //   });
    // };
    const startTimer = () => {
      startTime.value = Date.now();
      timer = setInterval(() => {
        elapsedTime.value += 1; // 每分累加（这里假设是每分钟，但间隔设置是1分钟，可能不是预期行为）
      }, 60000); // 每分钟更新一次
    };

    const stopTimer = () => {
      clearInterval(timer);
      const timeSpent = (Date.now() - startTime.value) / 60000;
      elapsedTime.value += timeSpent;
      saveElapsedTime(timeSpent);
    };

    const saveElapsedTime = (time) => {
      console.log(`User interacted with the page for ${time} minutes.`);
      // 你可以将时间保存到 Vuex、localStorage 或发送到服务器
      //添加时间
      store.commit("UserAccount/addBrowsingTime", time);
      //  console.log(Math.floor(store.getters["UserAccount/browsingTime"]));
      // console.log(store.getters["UserAccount/browsingTime"]);
    };

    const setupInteractionListeners = () => {
      const interactionEvents = [
        "scroll",
        "click",
        "keydown",
        "mousemove",
        "touchstart",
      ];
      interactionEvents.forEach((event) => {
        window.addEventListener(event, handleUserInteraction);
      });
    };

    const removeInteractionListeners = () => {
      const interactionEvents = [
        "scroll",
        "click",
        "keydown",
        "mousemove",
        "touchstart",
      ];
      interactionEvents.forEach((event) => {
        window.removeEventListener(event, handleUserInteraction);
      });
    };
    const handleBeforeUnload = () => {
      saveLookTime();
    };
    //写登录
    async function saveAccessLog() {
      try {
        const user = sessionStorage.getItem("user");
        const userId = user ? JSON.parse(user).userId : null; // 获取 userId，简化逻辑

        //const response = await axios.get("https://ipinfo.io/json"); // 异步获取IP信息
        const response = await getIpInfo(); // 异步获取IP信息
        // const { ip, city, region, country } = response.data; // 解构赋值获取响应中的数据
        console.log(response.data);
        // 组织数据，使用模板字符串简化拼接
        const data = {
          userId: userId,
          ipAddress: response.data.ipAddress,
          ipAnalysis: response.data.ipAnalysis, // 使用模板字符串拼接地理信息
          accessTime: new Date(), // 获取当前时间
        };
        // console.log(data);
        // // 通过 store 发送数据
        store.dispatch("Login/saveAccessLogs", { data: data });
        console.log(userId);
      } catch (error) {
        console.error("获取IP信息失败:", error); // 错误处理
      }
      console.log("init");
    }
    const saveLookTime = () => {
      const user = sessionStorage.getItem("user");
      if (user) {
        console.log(store.getters["UserAccount/browsingTime"]);
        store.dispatch("UserAccount/saveLookTime", {
          userId: JSON.parse(user).userId,
          lookTime: Math.floor(store.getters["UserAccount/browsingTime"]),
        });
      }
    };

    // 内容审核示例
    // const submitContent = async () => {
    //   try {
    //     //const apiKey = "sk-c046b5953891b0205bc45220af089885"; // 替换为你的API密钥
    //     const apiKey = process.env.VUE_APP_BAICHUAN_API_KEY; // 替换为你的API密钥
    //     const content = ref("اللعنة على أمك");
    //     const response = await axios.post(
    //       "https://api.baichuan-ai.com/v1/chat/completions",
    //       {
    //         model: "Baichuan3-Turbo", // 替换为具体的模型名称
    //         messages: [
    //           {
    //             role: "system",
    //             content: "请审查以下内容是否包含不良内容。回答 true 或 false。",
    //           },
    //           { role: "user", content: content.value },
    //         ],
    //         max_tokens: 100,
    //       },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${apiKey}`,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );
    //     if (
    //       response.data.choices[0].message.content === "true" ||
    //       response.data.choices[0].message.content === "True"
    //     ) {
    //       alert("内容审核不通过");
    //     } else {
    //       alert("内容审核通过");
    //     }
    //     console.log(response.data.choices[0].message.content); // 审核结果
    //   } catch (error) {
    //     console.error("审核失败:", error);
    //   }
    // };
    onMounted(() => {
      // submitContent();
     // isLinkValid();
      const user = sessionStorage.getItem("user");
      if (user !== null) {
        store.dispatch("UserAccount/getUserActive", {
          userId: JSON.parse(user).userId,
        });
        setupInteractionListeners();
      }
      saveAccessLog();

      //页面关闭或重新时
      window.addEventListener("beforeunload", handleBeforeUnload);
    });

    onBeforeUnmount(() => {
      saveLookTime();
      removeInteractionListeners();
      stopTimer();
      //保存浏览时长到数据库
    });

    // 如果需要响应式地观察某些数据变化，可以使用watchEffect等

    return {};
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  //color: #fcb540;
  // color: black;
  //background-color: rgb(214, 188, 235);
  /* margin-top: 60px; */
}
</style>
