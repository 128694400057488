export let newType = [
  { name: "national" },
  { name: "politics" },
  { name: "economy" },
  { name: "sports" },
  { name: "world" },
  { name: "entertainment" },
  { name: "technology" },
  { name: "culture" },
  { name: "life" },
  { name: "travel" },
];
export let websitesType = [
  { name: "portal" },
  { name: "video" },
  { name: "news" },
  { name: "social" },
  { name: "design" },
  { name: "shopping" },
  { name: "celebrity" },
  { name: "music" },
  { name: "image" },
  { name: "sports" },
  { name: "anime" },
  { name: "game" },
  { name: "travel" },
  { name: "automobile" },
  { name: "it" },
  { name: "magazine" },
  { name: "forum" },
  { name: "blog" },
  { name: "search" },
  { name: "fashion" },
  { name: "food" },
];
