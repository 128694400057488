<template>
  <!-- 页脚 -->
  <!-- 页脚 -->
  <footer
    class="text-white text-center text-lg-start"
    style="background-color: #00164d"
  >
    <div class="container p-2">
      <div class="row text-center">
        <!-- 第一列 -->
        <div class="col-lg-4 col-md-6">
          <h5 class="text-uppercase">公司信息</h5>
          <ul class="list-unstyled">
            <li>
              <a href="#!" class="text-white">关于我们</a>
            </li>
            <li>
              <a href="#!" class="text-white">联系我们</a>
            </li>
          </ul>
        </div>
        <!-- 第二列 -->
        <div class="col-lg-4 col-md-6">
          <h5 class="text-uppercase">帮助中心</h5>
          <ul class="list-unstyled">
            <li>
              <a href="#!" class="text-white">常见问题</a>
            </li>
            <li>
              <a href="#!" class="text-white">反馈</a>
            </li>
          </ul>
        </div>
        <!-- 第三列 -->
        <div class="col-lg-4 col-md-6">
          <h5 class="text-uppercase">社交媒体</h5>
          <ul class="list-unstyled">
            <li>
              <a href="#!" class="text-white">Facebook</a>
            </li>
            <li>
              <a href="#!" class="text-white">Twitter</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 页脚底部 -->
    <!-- <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
      © 2024 公司名称. 保留所有权利.
    </div> -->
  </footer>
</template>

<script>
export default {
  name: "homefooter-Vue",
  components: {},
  methods: {},
};
</script>

<style lang="less"></style>
