<template>
  <div class="text-start p-4 shadow mb-2">
    <h2>{{ $t("MY.personal_posts") }}</h2>
    <hr />
    <!-- 搜索框 -->
    <div class="row container justify-content-end mb-2">
      <input
        type="search"
        class="col-7 search my-auto"
        :placeholder="$t('MY.search')"
        v-model="keyWord"
        aria-label="Search"
        @keypress.enter="searchNews()"
      />
      <button class="col-2 col-lg-1 btn m-0 p-0" @click="searchNews()">
        <van-icon class="m-0 p-0" name="search" size="1.5rem" color="white" />
      </button>
      <button
        class="col-2 col-lg-1 btn text-white m-0 p-0"
        data-bs-toggle="offcanvas"
        href="#addNewsOffcanvas"
        role="button"
        aria-controls="addNewsOffcanvas"
      >
        <van-icon class="m-0 p-0" name="add-o" size="1.5rem" color="white" />
      </button>
    </div>
    <!-- 添加表单 -->
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="addNewsOffcanvas"
      aria-labelledby="addNewsOffcanvasLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="addNewsOffcanvasLabel">发布新闻</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <el-form :model="form" label-position="right" label-width="auto">
          <el-form-item :label="$t('MyLink.country')" label-position="right">
            <el-select
              v-model="form.countryCode"
              :placeholder="$t('MyLink.please_select_country')"
            >
              <el-option
                v-for="(item, key) in countryList"
                :key="key"
                :label="item.countryCode"
                :value="item.countryCode"
              >
                <span>
                  <img
                    :src="'/countryImg/' + item.img"
                    style="width: 25px; height: 20px; margin-right: 5px"
                  />
                  .{{ item.countryCode }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('MyLink.language')" label-position="right">
            <el-select
              v-model="form.languageCode"
              :placeholder="$t('MyLink.please_select_language')"
            >
              <el-option
                v-for="(item, key) in languageList"
                :key="key"
                :label="item.languageName"
                :value="item.languageCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('MyLink.type')" label-position="right">
            <el-select
              v-model="form.newType"
              :allow-create="$t('MyLink.please_select_type')"
            >
              <el-option
                v-for="(item, key) in newType"
                :key="key"
                :label="$t('NewType.' + item.name)"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('MyLink.image_link')" label-position="right">
            <el-input v-model="form.imgUrl" autocomplete="off" />
          </el-form-item>
          <el-form-item :label="$t('MyLink.title')" label-position="right">
            <el-input v-model="form.title" autocomplete="off" />
          </el-form-item>
          <el-form-item :label="$t('MyLink.share_link')" label-position="right">
            <el-input v-model="form.link" autocomplete="off" />
          </el-form-item>
        </el-form>
        <div class="text-center mt-3">
          <el-button
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            >{{ $t("MyLink.cancel") }}</el-button
          >
          <el-button
            type="primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            @click="addNews()"
          >
            {{ $t("MyLink.confirm") }}
          </el-button>
        </div>
      </div>
    </div>

    <!-- 新闻列表 -->
    <div class="container-fluid mb-3 scroll-container bg-white text-black pt-2">
      <!-- 新闻信息 -->
      <!-- <div class="container-fluid pb-3"> -->
      <div
        class="row mb-1 bg-transparent-70"
        v-for="(item, key) in newsList"
        :key="key"
      >
        <div class="col-12 col-lg-3 col-md-3 rounded-1 container mb-2 mb-lg-0">
          <img class="image" :src="item.imgUrl" />
        </div>
        <div class="col-12 col-lg-9 col-md-9 rounded-1">
          <div class="text-start item-text d-flex flex-column">
            <a class="href" @click="toNewsDetail(key)"
              ><h5>
                {{ item.title }}
              </h5></a
            >
            <p class="text-secondary">{{ item.content }}</p>
            <p class="text-secondary mt-auto">
              <span>{{ formatTime(item) }}</span>
            </p>
          </div>
        </div>
        <div
          v-if="item.userId"
          class="col-12 row justify-content-end text-secondary"
        >
          <span class="col-3 col-lg-1 col-md-1"
            ><van-icon name="eye-o" /> {{ item.look }}</span
          >
          <span class="col-7 col-lg-9 col-md-9"
            ><van-icon name="star-o" class="me-1" />{{ item.collect }}</span
          >
          <span
            class="col-2 col-lg-2 col-md-2"
            @click="deleteNews(key, item.id)"
            ><van-icon name="delete-o"
          /></span>
        </div>
      </div>
      <!-- 加载中 -->
      <!-- 已全部加载 -->
      <div v-if="isAll" class="text-center">
        {{ $t("MyLink.all_content_loaded") }}
      </div>
      <div v-if="!isAll" ref="loader" class="row mb-2">
        <div class="col-12 col-lg-3 col-md-3 rounded-1 container mb-2">
          <h1
            class="placeholder col-12 bg-secondary"
            style="width: 100%; height: 100%"
          ></h1>
        </div>
        <div class="col-12 col-lg-9 col-md-9 rounded-1">
          <div class="text-start item-text d-flex flex-column">
            <h3 class="card-title placeholder-glow">
              <span class="placeholder col-10"></span>
            </h3>
            <p class="placeholder-glow mt-2">
              <span class="placeholder bg-secondary col-8"></span>
            </p>
            <p class="placeholder-glow mt-auto">
              <span class="placeholder col-4 me-2 bg-secondary"></span>
              <span class="placeholder col-4 bg-secondary"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";
import { countryList } from "@/data/countryList";
import { languageList } from "@/data/languageList";
import { newType } from "@/data/Type";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { reactive, ref, computed, onMounted, watch } from "vue";
import axios from "axios";
export default {
  name: "myLink-Vue",
  components: {},
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const dialogFormVisible = ref(false);
    const newsList = computed(() => store.getters["UserLink/newsList"]);
    const pages = computed(() => store.getters["UserLink/totalPages"]);
    const currentPage = computed(() => store.getters["UserLink/currentPage"]);
    const observer = ref(null);
    const loader = ref(null);
    const isAll = ref(false);
    const formLabelWidth = "100px";
    const keyWord = ref("");
    const isSearch = ref(false);
    const form = reactive({
      countryCode: "",
      languageCode: "",
      newType: "",
      imgUrl: "",
      title: "",
      link: "",
    });
    const clearForm = () => {
      form.countryCode = "";
      form.languageCode = "";
      form.newType = "";
      form.imgUrl = "";
      form.title = "";
      form.link = "";
    };
    //判断输入值是否为空
    const confirmIsNull = () => {
      return (
        form.countryCode !== "" &&
        form.languageCode !== "" &&
        form.newType !== "" &&
        form.title !== "" &&
        form.link !== ""
      );
    };
    //审核资源链接是否有效
    const isLinkValid = async (link) => {
      try {
        const response = await axios.get(link, {
          // 注意：这里不能使用 `no-cors`，因为 axios 不支持这种模式。
          // 你可以通过代理或服务器端解决 CORS 问题。
          timeout: 8000, // 设置请求超时
        });

        if (response.status === 200) {
          return true;
        } else {
          console.log("资源无效，状态码:", response.status);
        }
        return false;
      } catch (error) {
        if (error.response) {
          // 请求已发出，但服务器响应了一个状态码，非 2xx 范围
          console.log("资源无效，状态码:", error.response.status);
        } else if (error.request) {
          // 请求已发出，但没有收到服务器的响应
          console.log("没有收到服务器响应");
        } else {
          // 其他错误
          console.log("请求失败:", error.message);
        }
        return false;
      }
      // await fetch(
      //   "https://pbs.twimg.com/profile_images/1824316060109250560/pYYMzlfo_normal.png",
      // )
      //   .then((response) => {
      //     console.log(response);
      //     if (response.ok) {
      //       console.log("资源有效");
      //     } else {
      //       console.log("资源无效，状态码:", response.status);
      //     }
      //   })
      //   .catch(() => {
      //     console.log("Link is not valid");
      //     return false;
      //   });
    };
    const checkImgLink = async () => {
      if (form.imgUrl !== "") {
        const isValid = await isLinkValid(form.imgUrl);
        if (!isValid) {
          ElMessage({
            type: "error",
            message: t("MyLink.invalid_image_link"),
            // message: "图片链接无效",
          });
          return false;
        }
      }
      return true;
    };
    const submitContent = async (content) => {
      try {
        const apiKey = "sk-c046b5953891b0205bc45220af089885"; // 替换为你的API密钥
        //const apiKey = process.env.VUE_APP_BAICHUAN_API_KEY; // 替换为你的API密钥
        //const content = ref("اللعنة على أمك");
        const response = await axios.post(
          "https://api.baichuan-ai.com/v1/chat/completions",
          {
            model: "Baichuan3-Turbo", // 替换为具体的模型名称
            messages: [
              {
                role: "system",
                content: "请审查以下内容是否包含不良内容。回答 true 或 false。",
              },
              { role: "user", content: content },
            ],
            max_tokens: 100,
          },
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data.choices[0].message.content);
        if (
          response.data.choices[0].message.content === "true" ||
          response.data.choices[0].message.content === "True"
        ) {
          return false;
          // alert("内容审核不通过");
        } else {
          return true;
          //alert("内容审核通过");
        }
        // 审核结果
      } catch (error) {
        console.error("审核失败:", error);
        return false;
      }
    };

    const addNews = async () => {
      if (!confirmIsNull()) {
        return ElMessage({
          type: "info",
          message: t("MyLink.output_value_required_except_image_link"),
          // message: "除了图片链接，输入值不能为空",
        });
      }

      const isImgValid = await checkImgLink();
      if (!isImgValid) return;

      const isLinkValidResult = await isLinkValid(form.link);
      if (!isLinkValidResult) {
        return ElMessage({
          type: "error",
          message: t("MyLink.invalid_share_link"),
          // message: "发布链接无效",
        });
      }
      ElMessage({
        type: "info",
        message: t("MyLink.under_review"),
        // message: "审核中",
      });
      const isContentValid = await submitContent(form.title);
      if (!isContentValid) {
        return ElMessage({
          type: "error",
          message: t("MyLink.review_rejected"),
          // message: "审核不通过",
        });
      }
      ElMessage({
        type: "success",
        message: t("MyLink.review_success"),
        // message: "审核成功",
      });
      const user = getUser();
      if (user !== null) {
        store.dispatch("UserLink/addUserNew", {
          userId: user.userId,
          title: form.title,
          link: form.link,
          languageCode: form.languageCode,
          countryCode: form.countryCode,
          typeName: form.newType,
          imgUrl: form.imgUrl,
        });
        ElMessage({
          type: "success",
          message: t("MyLink.publish_success"),
        });
        cancel();
        setTimeout(() => {
          init();
        }, 70);
      }
    };
    const getUser = () => JSON.parse(sessionStorage.getItem("user"));

    const cancel = () => {
      // const offcanvas = new bootstrap.Offcanvas(
      //   document.getElementById("offcanvasExample")
      // );
      //offcanvas.hide();
      clearForm();
      dialogFormVisible.value = false;
    };
    const deleteNews = (key, id) => {
      ElMessageBox.confirm(t("MyLink.confirm_delete"), {
        confirmButtonText: t("MyLink.confirm"),
        cancelButtonText: t("MyLink.cancel"),
        type: "warning",
      })
        .then(() => {
          store.dispatch("UserLink/deleteUserNew", { id: id });
          // store.commit(
          //   "UserLink/setNewsListFirst",
          //   newsList.value.splice(key, 1)
          // );
          setTimeout(() => {
            init();
          }, 300);
          console.log(id);
        })
        .catch(() => {});
    };
    //跳转我的新闻详情页
    const toNewsDetail = (key) => {
      if (isSearch.value) {
        store.commit("UserLink/setKeyWord", keyWord.value);
      } else {
        store.commit("UserLink/setKeyWord", null);
      }
      if (newsList.value[key].userId) {
        store.dispatch("Home/addLook", { newId: newsList.value[key].id });
      }
      router.push({ path: "/navframeMylink", query: { key: key } });
    };
    //点击搜索
    const searchNews = () => {
      const user = getUser();
      if (keyWord.value !== "") {
        if (user !== undefined) {
          store.dispatch("UserLink/selectUserNewsKeyWord", {
            page: 1,
            userId: user.userId,
            keyWord: keyWord.value,
          });
          isSearch.value = true;
        }
      } else {
        if (user !== undefined) {
          store.dispatch("UserLink/getUserNews", {
            page: 1,
            userId: user.userId,
          });
          isSearch.value = false;
        }
      }
    };
    watch(
      () => [currentPage.value],
      () => {
        isAll.value = currentPage.value >= pages.value;
      }
    );
    //监听底部
    const createObserver = () => {
      const options = {
        root: null, // 使用视口作为容器
        rootMargin: "0px",
        threshold: 0.6, // 当加载组件 100% 进入视口时触发回调
      };
      observer.value = new IntersectionObserver(handleIntersect, options);
      observer.value.observe(loader.value);
    };
    //事件处理
    const handleIntersect = (entries) => {
      if (entries[0].isIntersecting) {
        //加载数据
        const user = getUser();
        if (isSearch.value) {
          if (keyWord.value !== "") {
            if (user !== null) {
              store.dispatch("UserLink/selectUserNewsKeyWord", {
                page: currentPage.value + 1,
                userId: user.userId,
                keyWord: keyWord.value,
              });
            }
          }
        } else {
          if (user !== null) {
            store.dispatch("UserLink/getUserNews", {
              page: currentPage.value + 1,
              userId: user.userId,
            });
          }
        }
      }
    };
    
    const formatTime = (item) => {
      if (item !== null) {
        const timestamp = item.time;
        const date = new Date(timestamp);

        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1
        const day = String(date.getUTCDate()).padStart(2, "0");
        const hours = String(date.getUTCHours()).padStart(2, "0");
        const minutes = String(date.getUTCMinutes()).padStart(2, "0");
        const seconds = String(date.getUTCSeconds()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
      } else {
        return;
      }
    };
    //初始化
    const init = () => {
      const user = getUser();
      if (user !== null) {
        store.dispatch("UserLink/getUserNews", {
          page: 1,
          userId: user.userId,
        });
      }
    };
    onMounted(() => {
      init();
      createObserver();
    });
    return {
      formLabelWidth,
      countryList,
      languageList,
      newType,
      form,
      newsList,
      dialogFormVisible,
      formatTime,
      isAll,
      loader,
      keyWord,
      searchNews,
      deleteNews,
      cancel,
      addNews,
      toNewsDetail,
    };
  },
};
</script>

<style>
.scroll-container {
  overflow: auto;
  max-height: 100vh; /* For large screens, height should be 100vh */
  width: 100%; /* For small screens, width should be 100vw */
}
</style>
